import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['remove', 'container']

  connect() {
    this.containerTarget.addEventListener('change', this.removeLessons.bind(this))
  }

  removeLessons(event) {
    if (event.target.tagName.toLowerCase() === 'input' || event.target.tagName.toLowerCase() === 'select') {
      this.removeTargets.forEach(element => $(element).remove());
    }
  }
}
