import {Controller} from "stimulus"
import feather from "feather-icons";

export default class extends Controller {
  static targets = [
    'fullScreenIcon',
    'fullScreenButton'
  ]

  connect(){
    this.fullScreenButtonTarget.classList.toggle('hidden', !this._fullscreen_supported());
  }

  togglefullScreen(){
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else if (document.webkitFullscreenElement) {
      document.webkitExitFullscreen();
    } else {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.webkitRequestFullscreen) { /* Safari */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) { /* IE11 */
        this.element.msRequestFullscreen();
      }
    }
  }

  refreshFullScreenIcon(){
    if (document.fullscreenElement || document.webkitFullscreenElement) {
      this.fullScreenIconTarget.dataset.feather = 'minimize';
    } else {
      this.fullScreenIconTarget.dataset.feather = 'maximize';
    }
    feather.replace();
  }

  _fullscreen_supported(){
    return (this.element.requestFullscreen || this.element.webkitRequestFullscreen || this.element.msRequestFullscreen);
  }
}
