import { Controller } from "stimulus"
import moment from "moment"
import "daterangepicker"

export default class extends Controller {
  connect() {
    this.initializeDateRangePicker(this.element)

    $(document).on('cocoon:after-insert', (e, added_html) => {
      this.initializeDateRangePicker(added_html);
    });

    $('#modal-window').on('shown.bs.modal', (e) => {
      this.initializeDateRangePicker(e.relatedTarget);
    })
  }

  halfAcadamicYear() {
    if (moment().month() == 0) {
      return moment().endOf('month');
    } else {
      return moment().add(1, 'year').set('month', 0).endOf('month');
    }
  }

  acadamicYear() {
    if (moment().month() < 7) {
      return moment().set('month', 5).endOf('month');
    } else {
      return moment().add(1, 'year').set('month', 5).endOf('month');
    }
  }

  initializeDateRangePicker(parent_element) {
    $('input[data-date-range-picker-target="range"]', parent_element).on('apply.daterangepicker', function (ev, picker) {
      $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
    });

    $('input[data-date-range-picker-target="range"]', parent_element).daterangepicker({
      autoUpdateInput: true,
      ranges: {
        'Tento měsíc': [moment().startOf('month'), moment().endOf('month')],
        'Příští měsíc': [moment().add(1, 'month').startOf('month'), moment().add(1, 'month').endOf('month')],
        'Minulý měsíc': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        'Minulý rok': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
        'Minulých 90 dní': [moment().subtract(90, 'days'), moment()],
        'Příštích 90 dní': [moment(), moment().add(90, 'days')],
        'Celý rok': [moment().startOf('year'), moment().endOf('year')],
        'Do konce roku': [moment(), moment().endOf('year')],
        'Do konce příštího roku': [moment(), moment().add(1, 'year').endOf('year')],
        'Do konce pololetí': [moment(), this.halfAcadamicYear()],
        'Do konce školního roku': [moment(), this.acadamicYear()],
      },
      locale: {
        format: "DD/MM/YYYY",
        separator: " - ",
        applyLabel: "OK",
        cancelLabel: "Zrušit",
        fromLabel: "Od",
        toLabel: "Do",
        customRangeLabel: "Vlastní",
        weekLabel: "W",
        daysOfWeek: [
          "Ne",
          "Po",
          "Út",
          "St",
          "Čt",
          "Pá",
          "So"
        ],
        monthNames: [
          "Leden",
          "Únor",
          "Březen",
          "Duben",
          "Květen",
          "Červen",
          "Červenec",
          "Srpen",
          "Září",
          "Říjen",
          "Listopad",
          "Prosinec"
        ],
        firstDay: 1
      }
    });
  }
}
