import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.initializeFeather();
    this.initializeTooltip();
    this.initializeSidebarToggle();
  }

  initializeFeather() {
    feather.replace();
  }

  initializeTooltip() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  initializeSidebarToggle() {
    $("#sidebarToggle").on("click", (e) => {
      e.preventDefault();
      $("body").toggleClass("sidenav-toggled");
    });
  }
}
