import { Controller } from "stimulus"
import { filter, size, replace, map } from "lodash";

export default class extends Controller {
  static targets = ["checkbox", "masterCheckbox", "button", "selectedLessons", "modalLink"]

  connect() {
    this.masterCheckboxTarget.addEventListener("click", () => {
      this.checkboxTargets.forEach(checkbox => {
        checkbox.checked = this.masterCheckboxTarget.checked;
      });

      if (this.masterCheckboxTarget.checked) {
        $(this.buttonTarget).addClass('d-block').removeClass('d-none');
      } else {
        $(this.buttonTarget).addClass('d-none').removeClass('d-block');
      }

      this.updateChecked();
    });

    this.checkboxTargets.forEach(checkbox => {
      checkbox.addEventListener("click", (event) => {
        if(event.target.checked == false) {
          this.masterCheckboxTarget.checked = false;
        } else {
          $(this.buttonTarget).addClass('d-block').removeClass('d-none');
        }

        this.updateChecked();
      })
    })
  }

  updateChecked() {
    const translations = JSON.parse(this.data.get('selectedLessonsText'));
    const selectedCheckboxes = filter(this.checkboxTargets, checkbox => checkbox.checked);
    const count = size(selectedCheckboxes);
    let text = '';

    if(count == 1) {
      text = translations['one']
    } else if (count > 1 && count < 5) {
      text = replace(translations['few'], "%{count}", count);
    } else if (count >= 5) {
      text = replace(translations['other'], "%{count}", count);
    }

    const lessonIds = map(selectedCheckboxes, checkbox => checkbox.value)
    this.selectedLessonsTarget.innerHTML = text;
    this.modalLinkTarget.href = `${this.data.get('updatePath')}?${$.param({lesson_ids: lessonIds})}`
  }
}
