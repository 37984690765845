import {Controller} from "stimulus"
import feather from "feather-icons";

export default class extends Controller {
  static targets = [
    'localMicrophoneIcon',
    'localVideoIcon',
    'leaveRoomButton',
    'fullScreenButton'
  ]

  connect(){
    this.element['playerControls'] = this;
  }

  attachLocalParticipant(participant) {
    this.participant = participant;
    this._refreshIcons();
  }

  async toggleAudio() {
    await this.participant.setMicrophoneEnabled(!this.participant.isMicrophoneEnabled);
    this._refreshAudioIcon();
  }

  async toggleVideo() {
    await this.participant.setCameraEnabled(!this.participant.isCameraEnabled);
    this._refreshVideoIcon();
  }

  _refreshIcons(){
    this._refreshAudioIcon();
    this._refreshVideoIcon();
    this.fullScreenButtonTarget.disabled = !this.participant;
  }

  _refreshAudioIcon(){
    if (this.participant.isMicrophoneEnabled) {
      this.localMicrophoneIconTarget.dataset.feather = 'mic';
      this.localMicrophoneIconTarget.classList.remove('text-primary');
      this.localMicrophoneIconTarget.parentElement.classList.add('active');
    } else {
      this.localMicrophoneIconTarget.dataset.feather = 'mic-off';
      this.localMicrophoneIconTarget.classList.add('text-primary');
      this.localMicrophoneIconTarget.parentElement.classList.remove('active');
    }
    feather.replace();
  }

  _refreshVideoIcon(){
    if (this.participant.isCameraEnabled) {
      this.localVideoIconTarget.dataset.feather = 'video';
      this.localVideoIconTarget.classList.remove('text-primary');
      this.localVideoIconTarget.parentElement.classList.add('active');
    } else {
      this.localVideoIconTarget.dataset.feather = 'video-off';
      this.localVideoIconTarget.classList.add('text-primary');
      this.localVideoIconTarget.parentElement.classList.remove('active');
    }
    feather.replace();
  }
}
