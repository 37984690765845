// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

const cache = {};
function importAll(r) {
  r.keys().forEach((key) => (cache[key] = r(key)));
}

require("jquery")
require("@nathanvda/cocoon")
require("bootstrap")
import "@fortawesome/fontawesome-free/js/all";
import "@hotwired/turbo-rails";

import moment from 'moment'
window.moment = moment

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import feather from 'feather-icons'
import 'tom-select'

import '../stylesheets/application.scss'
import "controllers"
//import introJs from 'intro.js';

importAll(require.context('../images', true));

require("trix")
require("@rails/actiontext")

Rails.start()
ActiveStorage.start()

window.feather = feather; // from some reason webpacker not catch ajax:success event, so we call feather.raplace in some ajax response for refreshing icons
window.$ = jQuery; // from some reason webpacker not catch ajax:success event, so we call feather.raplace in some ajax response for refreshing icons
