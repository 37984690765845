import {Controller} from "stimulus"
import {
  LocalTrackPublication,
  ParticipantEvent, RemoteParticipant,
  Room,
  RoomEvent,
  Track,
  ScreenSharePresets
} from 'livekit-client';
import feather from 'feather-icons';
import ParticipantItemCreator from "../components/livekit/participant_item_creator";

export default class extends Controller {
  static values = {
    url: String,
    token: String,
    active: Boolean,
    logPrefix: String
  }

  static targets = [
    'bigView', 'participants',
    'leaveRoomButton', 'shareScreenButton', 'shareScreenButtonIcon',
    'userListButton', 'unpinButton', 'playerControls',
    'toggleAudioOutputButton', 'localAudioOutputIcon',
    'connectScreen'
  ]

  connect() {
    this.currentRoom = null;
    this.activatedScreenShare = false;
    this.initializeLogger();

    if(this.activeValue) {
      this.showConnectScreen();
    }
  }

  activate(e){
    this.activeValue = true;
  }

  activeValueChanged(){
    if(this.activeValue) {
      this.showConnectScreen();
    }
  }

  showConnectScreen(){
    // Give some time to stimulus to initialize on waiting screen element
    setTimeout(() => {
      this.connectScreenTarget.waitingScreen.allowedConnecting();
    }, 2000)
  }

  async connectToRoom() {
    this.bigViewTarget.livekitParent = this;
    this.initializeAudioOutput();
    const roomParams = {
      // adaptiveStream: {
      //   pixelDensity: 'screen',
      // },

      dynacast: true,

      publishDefaults: {
        simulcast: true,
        screenShareSimulcastLayers: [
          ScreenSharePresets.h1080fps15
        ]
      },
    }

    const presetAudioDeviceId = this.connectScreenTarget.dataset.presetAudioDeviceId;
    if (presetAudioDeviceId && presetAudioDeviceId !== '') {
      roomParams.audioCaptureDefaults = { deviceId: presetAudioDeviceId }
    }

    const presetVideoDeviceId = this.connectScreenTarget.dataset.presetVideoDeviceId;
    if (presetVideoDeviceId && presetVideoDeviceId !== '') {
      roomParams.videoCaptureDefaults = { deviceId: presetVideoDeviceId }
    }
    this.currentRoom = new Room(roomParams);

    this.currentRoom
      .on(RoomEvent.ParticipantConnected, (participant) => {
        this.addRemoteParticipant(participant);
        this.updateParticipantListVisibility();
        if(!this.bigViewTarget.bigView.participant) {
          this._findNewBigViewParticipant();
        }
      })
      .on(RoomEvent.ParticipantDisconnected, this.participantDisconnected.bind(this))
      .on(RoomEvent.Disconnected, this.handleRoomDisconnect.bind(this))
    await this.currentRoom.connect(this.urlValue, this.tokenValue, {autoSubscribe: true});
    this.addOpeningLinksInNewTab();
    this.leaveRoomButtonTarget.disabled = false;

    // Add listeners for already connected participants and add UI for them.
    this.currentRoom.participants.forEach((participant) => {
      this.addRemoteParticipant(participant);
      if (!this.activatedScreenShare && participant.getTrack(Track.Source.ScreenShare)) {
        this.activatedScreenShare = true;
      }
    });

    // Add listeners for local user
    this.addLocalParticipantListener(this.currentRoom.localParticipant);

    await this.currentRoom.localParticipant.setMicrophoneEnabled(this.connectScreenTarget.waitingScreen.presetMicrophoneCheckboxTarget.checked);
    await this.currentRoom.localParticipant.setCameraEnabled(this.connectScreenTarget.waitingScreen.presetCameraCheckboxTarget.checked);

    this.shareScreenButtonTarget.disabled = this.activatedScreenShare;
    this.playerControlsTarget.classList.remove('invisible');
    this.playerControlsTarget.playerControls.attachLocalParticipant(this.currentRoom.localParticipant);
    this.updateParticipantListVisibility();
    this._findNewBigViewParticipant();
    // if we call this early, it will not show
    /*
    setTimeout(() => {
      this._findNewBigViewParticipant();
    }, 1000);
     */
  }

  addLocalParticipantListener(participant) {
    this.appendLog('local participant', participant.identity, 'connected', participant.metadata);
    participant
      .on(ParticipantEvent.LocalTrackPublished, (publication) => {
        // called when local user connect to room (their audio or video track is published) - called twice
        this.log("LocalTrackPublished");

        // local user trying to share screen
        if (!this.activatedScreenShare && publication.source === Track.Source.ScreenShare) {
          this.log("Screen sharing activated");
          this._showScreenShareOnBigView(participant, publication);
          this.refreshScreenShareIcon();
          ParticipantItemCreator.generate(participant, this)
        }
      })
      .on(ParticipantEvent.LocalTrackUnpublished, (publication) => {
        // called when local user disconnect from room - it is called twice - one for disconnecting audio and second for disconnect video
        this.log("LocalTrackUnpublished");
        if (publication.source === Track.Source.ScreenShare) {
          this.log("Screen sharing deactivated");
          this.activatedScreenShare = false;
          if (publication.videoTrack) {
            publication.videoTrack._mediaStreamTrack.stop();
          }

          // ignore refreshing big view if user pinned someone else
          if (this.pinned){
            // User pinned own screenshare
            if (this.bigViewTarget.bigView.participant === participant && this.bigViewTarget.bigView.videoSource === Track.Source.ScreenShare) {
              this.unpin()
            }
          } else {
            this._findNewBigViewParticipant();
          }
          this.refreshScreenShareIcon();
        }
      })
      .on(ParticipantEvent.TrackMuted, (pub) => {
        // User muted audio or turn off video
        this.appendLog('track was muted', pub.trackSid, participant.identity);
      })
      .on(ParticipantEvent.TrackUnmuted, (pub) => {
        // User unmuted audio or turn on video
        this.appendLog('track was unmuted', pub.trackSid, participant.identity);
      })

    ParticipantItemCreator.generate(participant, this);
  }

  addRemoteParticipant(participant) {
    this.appendLog('participant', participant.identity, 'connected', participant.metadata);
    participant
      .on(ParticipantEvent.TrackPublished, (pub) => {
        this.log(`TrackPublished from ${participant.identity}`);
      })
      .on(ParticipantEvent.TrackMuted, (pub) => {
        this.appendLog('track was muted', pub.trackSid, participant.identity);
      })
      .on(ParticipantEvent.TrackUnmuted, (pub) => {
        this.appendLog('track was unmuted', pub.trackSid, participant.identity);
      })
      .on(ParticipantEvent.IsSpeakingChanged, this.speakerChangedThrottle.bind(this, participant))
      .on(ParticipantEvent.TrackSubscribed, (_, publication) => {
        this.log(`subscribed to track ${publication.trackSid}, ${participant.identity}`);

        if (this.activatedScreenShare) {
          return;
        }

        if (publication.source === Track.Source.ScreenShare) {
          this.log("Screen sharing activated");
          this._showScreenShareOnBigView(participant, publication);
          this.shareScreenButtonTarget.disabled = true;
          ParticipantItemCreator.generate(participant, this)
        }
      })
      .on(ParticipantEvent.TrackUnsubscribed, (_, publication) => {
        if (publication.source === Track.Source.ScreenShare) {
          this.log("Screen sharing deactivated");
          this.shareScreenButtonTarget.disabled = false;
          this.activatedScreenShare = false;
          if (this.pinned){
            if (this.bigViewTarget.bigView.participant === participant && this.bigViewTarget.bigView.videoSource === Track.Source.ScreenShare) {
              this.unpin()
            }
          } else {
            this._findNewBigViewParticipant(publication.trackSid)
          }
        }
        this.log(`unsubscribed from track ${publication.trackSid}`);
      })

    ParticipantItemCreator.generate(participant, this);
  }

  participantDisconnected(participant) {
    ["camera", "screen-share"].forEach((type) => {
      const participantElement = document.getElementById(ParticipantItemCreator.getParticipantItemId(participant, type))
      if (participantElement) {
        participantElement.remove();
      }
    })

    if (this.bigViewTarget.bigView.participant === participant) {
      if (this.pinned) {
        this.unpin();
      } else {
        this._findNewBigViewParticipant();
      }
    }
    this.updateParticipantListVisibility(); // Refresh count of participants
    this.appendLog('participant', participant.sid, 'disconnected');
  }

  speakerChangedThrottle(participant, speakerTalking) {
    this.log("speaker changed")
    if (this.pinned || this.activatedScreenShare){
      return
    }

    this.assignNewCurrentSpeaker(participant)

    /*

    // No one talking yet
    if (!this.currentSpeaker && speakerTalking) {
      this.currentSpeaker = participant;
      this.showParticipantOnBigView(this.currentSpeaker);
    }
    // Current talking person is currently in focus on big screen and talking
    else if (this.currentSpeaker === participant && speakerTalking) {
      clearTimeout(this.activeSpeakerTimeout);
      this.activeSpeakerTimeout = null;
    }
    // Current talking person is not in focus on big screen and talking
    else if (this.currentSpeaker !== participant && speakerTalking && !this.activeSpeakerTimeout) {
      this.activeSpeakerTimeout = setTimeout(this.assignNewCurrentSpeaker.bind(this, participant), 3000)
    }
     */
  }

  assignNewCurrentSpeaker(participant){
    this.log("Assigning new speaker")
    this.currentSpeaker = this.currentRoom.activeSpeakers.find(speaker => speaker instanceof RemoteParticipant);

    if (!this.currentSpeaker) {
      this.currentSpeaker = participant;
    }

    if (this.currentSpeaker !== this.bigViewTarget.bigView.participant) {
      this.bigViewTarget.bigView.switchVideo(this.currentSpeaker, Track.Source.Camera);
    }

    clearTimeout(this.activeSpeakerTimeout);
    this.activeSpeakerTimeout = null;
  }

  _showScreenShareOnBigView(participant, publication) {
    if (this.pinned) {
      return;
    }

    this.bigViewTarget.bigView.switchVideo(participant, Track.Source.ScreenShare);
    this.activatedScreenShare = true;
    publication.videoTrack._mediaStreamTrack.addEventListener('ended', () => {
      if (publication instanceof LocalTrackPublication) {
        this.currentRoom.localParticipant.setScreenShareEnabled(false);
      }
    })
  }

  _findNewBigViewParticipant(ignore_publication = null){
    if (!this.currentRoom) {
      return 
    }

    if (this.currentRoom.participants.size === 0) {
      if (this.currentRoom.localParticipant.isScreenShareEnabled) {
        this._showScreenShareOnBigView(this.currentRoom.localParticipant, this.currentRoom.localParticipant.getTrack(Track.Source.ScreenShare));
      } else {
        this.bigViewTarget.bigView.switchVideo(null, null);
      }
      return
    }

    let screenshareParticipant;

    // Dont use this.currentRoom.participants.values().find as it throw exception in safari
    this.currentRoom.participants.forEach((remoteParticipant, participant_identity, map) => {
      if (screenshareParticipant) {
        return;
      }
      const screenshare = remoteParticipant.getTrack(Track.Source.ScreenShare)
      // There is delay between update that user no longer screenshare, so we ignore screenshares with specific id
      if (screenshare && screenshare.trackSid !== ignore_publication) {
        screenshareParticipant = remoteParticipant;
      }
    })

    if (screenshareParticipant) {
      this._showScreenShareOnBigView(screenshareParticipant, screenshareParticipant.getTrack(Track.Source.ScreenShare))
      return;
    }

    this.bigViewTarget.bigView.switchVideo(this.currentRoom.participants.values().next().value, Track.Source.Camera);
  }

  handleRoomDisconnect() {
    [...this.participantsTarget.children].forEach((participantContainer) => {
      if (participantContainer.tagName !== 'TEMPLATE') {
        participantContainer.remove();
      }
    })
    this.currentRoom = null;
    this.removeOpeningLinksInNewTab();
  }

  refreshScreenShareIcon(){
    if (this.currentRoom && this.currentRoom.localParticipant.isScreenShareEnabled) {
      this.shareScreenButtonTarget.classList.add('active');
      this.shareScreenButtonIconTarget.classList.remove('text-primary');
    } else {
      this.shareScreenButtonIconTarget.classList.add('text-primary');
      this.shareScreenButtonTarget.classList.remove('active');
    }
    feather.replace();
  }

  // Temporary disabled
  async initializeAudioOutput(){
    return;
    const devices = await Room.getLocalDevices('audioinput');
    if (devices.find((device) => device.label === 'Speakerphone')) {
      this.toggleAudioOutputButtonTarget.disabled = false;
      this.toggleAudioOutputButtonTarget.classList.remove('hidden');
    }
  }

  // Not used, buggy on Android
  async toggleAudioOutput(){
    const devices = await Room.getLocalDevices('audioinput');
    if (this.toggleAudioOutputButtonTarget.classList.contains('active')) {
      const audioSpeaker = devices.find((device) => device.label === 'Headset earpiece')
      if (!audioSpeaker) { // select first audio device which isnt speaker
        devices.find((device) => device.label !== 'Speakerphone')
      }
      await this.currentRoom.switchActiveDevice('audioinput', audioSpeaker.deviceId);
      this.localAudioOutputIconTarget.classList.add('text-primary');
      this.toggleAudioOutputButtonTarget.classList.remove('active');
      this.localAudioOutputIconTarget.dataset.feather = 'headphones';
    } else {
      const audioSpeaker = devices.find((device) => device.label === 'Speakerphone')
      await this.currentRoom.switchActiveDevice('audioinput', audioSpeaker.deviceId);
      this.toggleAudioOutputButtonTarget.classList.add('active');
      this.localAudioOutputIconTarget.classList.remove('text-primary');
      this.localAudioOutputIconTarget.dataset.feather = 'volume-2';
    }
    feather.replace();
  }

  leaveRoom(){
    if (this.currentRoom) {
      this.currentRoom.disconnect();
      this.leaveRoomButtonTarget.disabled = true;

      // reset ui to default state
      this.currentRoom = null;
      this.activatedScreenShare = false;
      this.currentSpeaker = null;
      this.bigViewTarget.bigView.resetCurrent();

      this.connectScreenTarget.waitingScreen.reset();
      this.playerControlsTarget.classList.add('invisible');
      this.shareScreenButtonIconTarget.classList.add('text-primary');
      this.shareScreenButtonTarget.classList.remove('active');
      this.participantsTarget.classList.add('hidden');
      this.userListButtonTarget.classList.add('hidden');
    }
  }

  async shareScreen(){
    if (!this.activatedScreenShare) {
      await this.currentRoom.localParticipant.setScreenShareEnabled(true, {audio: true});
    } else {
      if (this.currentRoom.localParticipant.isScreenShareEnabled) {
        this.activatedScreenShare = false;
        await this.currentRoom.localParticipant.setScreenShareEnabled(false);
      }
    }
  }

  updateParticipantListVisibility(){
    if(this.currentRoom) {
      this.participantsTarget.classList.remove('hidden');
      this.userListButtonTarget.classList.remove('hidden');
      this.userListButtonTarget.querySelector('.users-count').innerHTML = this.currentRoom.participants.size + 1;
    } else {
      this.participantsTarget.classList.add('hidden');
      this.userListButtonTarget.classList.add('hidden');
    }
  }

  toggleUserList(){
    this.participantsTarget.classList.toggle('hidden');
  }

  showPinned(e){
    this.pinned = true;
    this.unpinButtonTarget.classList.remove('hidden');
    this.bigViewTarget.bigView.switchVideo(e.detail.participant, e.detail.type);
    this.log("pinned")
  }

  unpin(){
    this.pinned = false;
    this.unpinButtonTarget.classList.add('hidden');
    this.bigViewTarget.bigView.unpin();
    this._findNewBigViewParticipant();

    this.participantsTarget.querySelectorAll('.pinned').forEach(function(element){
      element.classList.remove('pinned');
    })
    this.log("unpinned")
  }

  addOpeningLinksInNewTab(){
    document.querySelectorAll('a[data-clickable="false"]').forEach((element) => {
      element.addEventListener('click', this.openLinksInNewWindow);
      element.addEventListener('tap', this.openLinksInNewWindow);
    })
  }

  removeOpeningLinksInNewTab(){
    document.querySelectorAll('a[data-clickable="false"]').forEach((element) => {
      element.removeEventListener('click', this.openLinksInNewWindow);
      element.removeEventListener('tap', this.openLinksInNewWindow);
    })
  }

  openLinksInNewWindow(e){
    e.preventDefault();
    window.open(e.currentTarget.href, '_blank').focus();
  }

  appendLog(...args) {
    this.log(args.join(' '));
  }

  initializeLogger(){
    let recordList = localStorage.getItem("loglist")
    if (recordList) {
      recordList = JSON.parse(recordList)
      if (!recordList.includes(this.logPrefixValue)){
        recordList.push(this.logPrefixValue)
      }
      localStorage.setItem("loglist", JSON.stringify(recordList))
    } else {
      localStorage.setItem("loglist", JSON.stringify([this.logPrefixValue]))
    }
  }
  log(data, includeInConsole = true){
    localStorage.setItem(`${this.logPrefixValue}_${Date.now()}`, JSON.stringify(data))
    if (includeInConsole) { console.log(data) }
  }

  closeRoomSettings(){
    document.querySelector('#switch-media-device').close();
    this._prefillModalAudioAndVideoSettings(); // reset selects
  }

  showRoomSettings(){
    document.querySelector('#switch-media-device').showModal();
    this._prefillModalAudioAndVideoSettings(); // reset selects
  }

  async saveRoomSettings(){
    await this.currentRoom.switchActiveDevice('audioinput', document.querySelector('#audio-settings-room').value);
    await this.currentRoom.switchActiveDevice('videoinput', document.querySelector('#video-settings-room').value);
    document.querySelector('#switch-media-device').close();
  }

  _prefillModalAudioAndVideoSettings(){
    const videoSettings = document.querySelector('#video-settings-room')
    const audioSettings = document.querySelector('#audio-settings-room')

    videoSettings.querySelectorAll('option').forEach((element) => element.remove());
    audioSettings.querySelectorAll('option').forEach((element) => element.remove());

    navigator.mediaDevices.enumerateDevices()
      .then((devices) => {
        devices.forEach((device) => {
          const option = document.createElement('option');
          option.text = device.label;
          option.value = device.deviceId;
          if (device.kind === 'audioinput'){
            option.selected = device.deviceId === this.currentRoom.getActiveDevice('audioinput');
            audioSettings.appendChild(option);
          } else if (device.kind === 'videoinput') {
            option.selected = device.deviceId === this.currentRoom.getActiveDevice('videoinput');
            videoSettings.appendChild(option);
          }
        })
      })
      .catch((err) => {
        console.error(`${err.name}: ${err.message}`);
      })
  }
}
