import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button", "tab", "check"];
  static values = {
    openTabIndex: Number
  }

  connect() {
    this.buttonTargets.forEach((button, index) => {
      button.dataset.index = index;
    });

    this.hideAllTabs();
    this.hideAllCheckmarks();

    if (!isNaN(this.openTabIndexValue)) {
      window.onload = () => {
        this.showButton(this.buttonTargets[this.openTabIndexValue]);
      };
    }
  }

  showTab(event) {
    let button = event.currentTarget;
    this.showButton(button);
  }

  showButton(button) {
    let index = button.dataset.index;

    this.deactivateAllButtons();
    this.hideAllTabs();
    this.hideAllCheckmarks();

    button.classList.add('active');
    this.tabTargets[index].classList.remove('d-none');
    this.checkTargets[index].classList.remove('d-none');
  }

  hideAllTabs() {
    this.tabTargets.forEach(tab => {
      tab.classList.add('d-none');
    });
  }

  hideAllCheckmarks() {
    this.checkTargets.forEach(check => {
      check.classList.add('d-none');
    });
  }

  deactivateAllButtons() {
    this.buttonTargets.forEach(button => button.classList.remove('active'));
  }
}
