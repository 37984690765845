import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content", "toggle"]

  connect() {
    this.showOrHideToggle();
  }

  toggle() {
    this.contentTarget.style.height = 'auto';
    this.toggleTarget.style.display = 'none';
  }

  showOrHideToggle() {
    let contentHeight = this.contentTarget.scrollHeight;
    if(contentHeight < 50) {
      this.contentTarget.style.height = 'auto';
      this.toggleTarget.style.display = 'none';
    } else {
      this.contentTarget.style.height = '30px';
      this.toggleTarget.style.display = 'inline-block';
    }
  }
}
