import { Track } from 'livekit-client';

export default class ParticipantItemCreator {
  constructor(participant, livekitParent) {
    this.participant = participant
    this.livekitParent = livekitParent
  }

  create() {
    this.generateParticipantItem('camera');
    if(this.participant && this.participant.getTrack(Track.Source.ScreenShare)) {
      this.generateParticipantItem('screen-share');
    }
  }

  generateParticipantItem(type) {
    const elementId = ParticipantItemCreator.getParticipantItemId(this.participant, type);
    if (document.querySelector(`#${elementId}`)) return;
    
    const participantContainer = document.querySelector(`#participant-item-${type}-template`).content.firstElementChild.cloneNode(true);
    participantContainer.id = elementId;
    participantContainer.participant = this.participant;
    participantContainer.livekitParent = this.livekitParent;
    this.livekitParent.participantsTarget.appendChild(participantContainer);
  }

  static generate(participant, livekitParent){
    const creator = new ParticipantItemCreator(participant, livekitParent)
    creator.create();
  }

  static getParticipantItemId(participant, type) {
    return `participant-${type}-${participant.identity}`
  }
}
