import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.element.addEventListener("turbo:submit-end", this.clear.bind(this));
  }

  clear() {
    this.element.reset()
  }
}
