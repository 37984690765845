import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    currentUserId: String
  }

  static targets = ["avatar", "chatSelect"]

  connect(){
    const that = this;
    $(this.element).on('shown.bs.collapse', (event) => {
      that.scrollToLastMessage();
      that.recountNewMessages();
    })

    this.element.querySelectorAll(".message.new").forEach((element) => element.classList.remove("new"))

    this.observer = new MutationObserver((mutations) => {
      mutations.forEach(mutation => {
        if (mutation.type === 'childList' && mutation.target.tagName != 'OPTION') {
          that.refreshChat();
        }
      });
    })
    this.observer.observe(this.element, { childList: true, subtree: true })
  }
  scrollToLastMessage(){
    const messageWindow = this.activeChat().querySelector('.message-tab')
    messageWindow.scrollTop = messageWindow.scrollHeight;
    messageWindow.querySelectorAll(".message.new").forEach((element) => element.classList.remove("new"));
  }

  clearForm(e){
    e.target.reset();
  }

  refreshChat(){
    this.element.querySelectorAll(".message.new").forEach((element) => {
      if(this.currentUserIdValue === element.dataset.userId) {
        element.classList.add("own")
      }
    })

    if (this.element.classList.contains("show")) {
      this.scrollToLastMessage();
    }
    this.recountNewMessages();
  }

  activeChat(){
    return this.element.querySelector('.chat-tab:not(.d-none)')
  }

  recountNewMessages(){
    const count = this.element.querySelectorAll(".message.new").length
    const counter = document.querySelector('#show-chat .counter')
    if (count === 0) {
      counter.innerHTML = "";
      counter.classList.add('d-none');
      document.querySelector('#show-chat').classList.remove("unread")
    } else {
      counter.innerHTML = "(" + count + ")";
      counter.classList.remove('d-none');
      document.querySelector('#show-chat').classList.add("unread")
    }

    if (this.hasChatSelectTarget) {
      this.chatSelectTarget.querySelectorAll('option').forEach((selectOption) => {
        const unreadMessageCount = document.querySelectorAll(`#messages_${selectOption.value} .new`).length
        if (unreadMessageCount === 0) {
          selectOption.innerText = selectOption.dataset.originalName
        } else {
          selectOption.innerText = `(${unreadMessageCount}) ${selectOption.dataset.originalName}`
        }
      })
    }
  }

  changeTab(e) {
    this.element.querySelectorAll('.chat-tab').forEach((chatTab) => {
      if (chatTab.id === `chat-tab-${e.target.value}`) {
        chatTab.classList.remove('d-none');
      } else {
        chatTab.classList.add('d-none');
      }
    })
    this.scrollToLastMessage();

    const selectedOption = e.target.options[e.target.selectedIndex]
    this.avatarTarget.querySelector('img').src = selectedOption.dataset.avatar
    this.recountNewMessages();
  }
}
