import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['spinner', 'link']

  connect() {
    this.linkTarget.addEventListener('ajax:beforeSend', this.start.bind(this));
    this.linkTarget.addEventListener('ajax:complete', this.stop.bind(this));
  }

  start() {
    event.currentTarget.disabled = true;
    $(this.spinnerTarget).removeClass('d-none');
  }

  stop() {
    this.linkTarget.disabled = false;
    $(this.spinnerTarget).addClass('d-none');
  }
}
