import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    if ($(this.element).data('date-range-picker-target') === 'range') {
      $(this.element).parent().on('apply.daterangepicker', this.submitForm.bind(this))
    } else if (this.element.tagName.toLowerCase() == 'label') {
      $(this.element).click((event) => {
        event.preventDefault();
        $(this.element).parent().find("input").prop( "checked", false );
        $(this.element).find("input").prop( "checked", true );
        this.submitForm(event);
      });
    }
    else {
      this.element.addEventListener('change', this.submitForm.bind(this))
    }
  }

  submitForm(event) {
    $(event.target).closest('form').submit()
  }
}
