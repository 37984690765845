import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["link"];

  connect() {
    this.element.style.cursor = 'pointer'
    this.element.addEventListener('click', this.click.bind(this));
  }

  click(event) {
    if (this.hasLinkTarget) {
      this.linkTarget.click()
    }
  }
}
