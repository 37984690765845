import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['show', 'hide', 'activate']

  connect(){
    document.addEventListener('countdownFinished', this.changeVisibility.bind(this))
  }

  changeVisibility(){
    this.showTargets.forEach(element => element.classList.remove('hidden'));
    this.hideTargets.forEach(element => element.classList.add('hidden'));

    if (this.hasActivateTarget) {
      this.activateTargets.forEach((element) => {
        const event = new Event('activate', { "bubbles": true, "cancelable": true })
        element.dispatchEvent(event);
      })
    }
  }
}
