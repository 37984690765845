import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "presetMicrophoneCheckbox",
    "presetCameraCheckbox",
    "connectScreenPreviewVideo",
    "connectButton"
  ]

  connect(){
    this.element['waitingScreen'] = this;
    this.connectButtonTarget.disabled = false;
    this.allowedConnect = false;
  }

  allowedConnecting(){
    this.allowedConnect = true;
    this._changeConnectButtonDisableState(false)
  }

  presetMicrophone(){
    this._changeConnectButtonDisableState(true)
    if (this.presetMicrophoneCheckboxTarget.checked) {
      navigator.mediaDevices.getUserMedia({audio: true})
        .then((audio) => {
          const tracks = audio.getTracks()
          this.element.dataset.presetAudioDeviceId = tracks[tracks.length - 1].getSettings().deviceId
          this._prefillModalAudioAndVideoSettings();
          this._changeConnectButtonDisableState(false)
        })
        .catch((err) => {
          this._changeConnectButtonDisableState(false)
          this.presetMicrophoneCheckboxTarget.checked = false;
          console.error(`error during initialization microphone: ${err}`)
        })
    } else {
      this._changeConnectButtonDisableState(false)
    }
  }

  presetCamera(){
    this._changeConnectButtonDisableState(true)
    if (this.presetCameraCheckboxTarget.checked) {
      const videoDeviceId = this.element.dataset.presetVideoDeviceId;
      let videoSettings = (videoDeviceId && videoDeviceId !== '') ? { video: { deviceId: { exact: videoDeviceId } } } : { video: true }
      navigator.mediaDevices.getUserMedia(videoSettings)
        .then((stream) => {
          const tracks = stream.getTracks()
          this.element.dataset.presetVideoDeviceId = tracks[tracks.length - 1].getSettings().deviceId
          this._changeConnectButtonDisableState(false)
          this.connectScreenPreviewVideoTarget.srcObject = stream;
          this.connectScreenPreviewVideoTarget.autoplay = true;
          this._prefillModalAudioAndVideoSettings();
          stream.getVideoTracks()[0].onended = this._resetToDefaultVideo.bind(this);
        }).catch((err) => {
          this._changeConnectButtonDisableState(false)
          this.presetCameraCheckboxTarget.checked = false;
          console.error(`error during initialization camera preview: ${err}`)
          this._resetToDefaultVideo();
        });
    } else {
      this._resetCameraPreview();
      this._changeConnectButtonDisableState(false)
    }
  }

  showSettingModal(){
    document.querySelector('#media-settings').showModal();
  }

  closeSettings() {
    document.querySelector('#media-settings').close();
    this._prefillModalAudioAndVideoSettings(); // reset selects
  }

  saveSettings() {
    this.element.dataset.presetAudioDeviceId = document.querySelector('#audio-settings').value;

    this.element.dataset.presetVideoDeviceId = document.querySelector('#video-settings').value;
    const videoDeviceId = this.element.dataset.presetVideoDeviceId;

    if (videoDeviceId && videoDeviceId !== '' && this.presetCameraCheckboxTarget.checked) {
      navigator.mediaDevices.getUserMedia({ video: { deviceId: { exact: videoDeviceId } } })
        .then((stream) => {
          this.connectScreenPreviewVideoTarget.srcObject = stream;
          this.connectScreenPreviewVideoTarget.autoplay = true;
          stream.getVideoTracks()[0].onended = this._resetToDefaultVideo.bind(this);
        })
    }
    document.querySelector('#media-settings').close();
  }

  connectToRoom(){
    this._resetCameraPreview();
    this.element.classList.remove('d-flex');
    this.element.classList.add('hidden');
    const event = new CustomEvent('connect-to-room',
      { bubbles: true,
        detail: {
          audioDeviceId: this.element.dataset.presetAudioDeviceId,
          videoDeviceId: this.element.dataset.presetVideoDeviceId
        }
      });
    this.element.dispatchEvent(event);
  }

  reset(){
    this._resetCameraPreview();
    this.presetMicrophoneCheckboxTarget.checked = false;
    this.presetCameraCheckboxTarget.checked = false;
    this.element.classList.add('d-flex');
    this.element.classList.remove('hidden');
  }

  _resetToDefaultVideo(){
    navigator.mediaDevices.getUserMedia({ video: true })
      .then((stream) => {
        const tracks = stream.getTracks()
        this.element.dataset.presetVideoDeviceId = tracks[tracks.length - 1].getSettings().deviceId
        this._changeConnectButtonDisableState(false)
        this.connectScreenPreviewVideoTarget.srcObject = stream;
        this.connectScreenPreviewVideoTarget.autoplay = true;
        this._prefillModalAudioAndVideoSettings();
      }).catch((err) => {
        this._changeConnectButtonDisableState(false)
        this.presetCameraCheckboxTarget.checked = false;
        console.error(`error during initialization camera preview: ${err}`)
      });
  }

  _prefillModalAudioAndVideoSettings(){
    const videoSettings = document.querySelector('#video-settings')
    const audioSettings = document.querySelector('#audio-settings')

    videoSettings.querySelectorAll('option').forEach((element) => element.remove());
    audioSettings.querySelectorAll('option').forEach((element) => element.remove());

    navigator.mediaDevices.enumerateDevices()
      .then((devices) => {
        devices.forEach((device) => {
          const option = document.createElement('option');
          option.text = device.label;
          option.value = device.deviceId;
          if (device.kind === 'audioinput'){
            if (device.deviceId === this.element.dataset.presetAudioDeviceId) {
              option.selected = true;
            }
            audioSettings.appendChild(option);
          } else if (device.kind === 'videoinput') {
            if (device.deviceId === this.element.dataset.presetVideoDeviceId) {
              option.selected = true;
            }
            videoSettings.appendChild(option);
          }
        })
      })
      .catch((err) => {
        console.error(`${err.name}: ${err.message}`);
      })
  }

  _resetCameraPreview(){
    if (this.connectScreenPreviewVideoTarget.srcObject){
      this.connectScreenPreviewVideoTarget.srcObject.getTracks().forEach((track) => {
        track.stop();
      })
      this.connectScreenPreviewVideoTarget.src = '';
      this.connectScreenPreviewVideoTarget.srcObject = null;
      this.connectScreenPreviewVideoTarget.autoplay = false;
    }
  }

  // Dont allow to change connect button state until allowedConnect is set (prevent user from logging in during countdown before lesson starts)
  _changeConnectButtonDisableState(state){
    if(this.allowedConnect) {
      this.connectButtonTarget.disabled = state;
    }
  }
}
