import { Controller } from "stimulus";
import introJs from 'intro.js';

export default class extends Controller {
  static values = {
    nextLabel: String,
    prevLabel: String,
    doneLabel: String
  }

  tourInstance = null;

  connect() {
    this.initializeTour();
  }

  initializeTour() {
    const steps = Array.from(this.element.querySelectorAll('[data-tour]')).map(el => {
      return {
        element: el,
        intro: el.getAttribute('data-intro')
      };
    });

    this.tourInstance = introJs();
    this.tourInstance.setOptions({
      steps: steps,
      nextLabel: this.nextLabelValue || 'Next',
      prevLabel: this.prevLabelValue || 'Previous',
      doneLabel: this.doneLabelValue || 'Done'
    });
  }

  startTour() {
    if (this.tourInstance) {
      this.tourInstance.start();
    }
  }
}
