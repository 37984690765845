import { Controller } from "stimulus"
import moment from 'moment'

export default class extends Controller {
  static targets = ['days', 'daysName', 'hours', 'hoursName', 'minutes', 'minutesName', 'seconds', 'secondsName']
  static values = {
    end: String
  }

  connect() {
    const timeinterval = setInterval(() => {
      const t = this.getTimeRemaining();

      this.daysTarget.innerHTML = t.days;
      this.daysNameTarget.innerHTML = this.getNameForCount(t.days, this.daysNameTarget.dataset.one, this.daysNameTarget.dataset.few, this.daysNameTarget.dataset.other);
      this.hoursTarget.innerHTML = t.hours;
      this.hoursNameTarget.innerHTML = this.getNameForCount(t.hours, this.hoursNameTarget.dataset.one, this.hoursNameTarget.dataset.few, this.hoursNameTarget.dataset.other);
      this.minutesTarget.innerHTML = t.minutes;
      this.minutesNameTarget.innerHTML = this.getNameForCount(t.minutes, this.minutesNameTarget.dataset.one, this.minutesNameTarget.dataset.few, this.minutesNameTarget.dataset.other);
      this.secondsTarget.innerHTML = t.seconds;
      this.secondsNameTarget.innerHTML = this.getNameForCount(t.seconds, this.secondsNameTarget.dataset.one, this.secondsNameTarget.dataset.few, this.secondsNameTarget.dataset.other);
      if (t.total <= 0) {
        clearInterval(timeinterval);
        const event = new Event('countdownFinished', { "bubbles": true, "cancelable": true })
        this.element.dispatchEvent(event);
        
      }
    }, 1000, this);
  }

  getNameForCount(count, one, few, other) {
    if(count == 1) {
      return one
    } else if (count > 1 && count < 5) {
      return few;
    } else {
      return other
    }
  }

  getTimeRemaining() {
    const total = moment(this.endValue).unix() - moment().unix();
    const seconds = Math.floor( (total) % 60 );
    const minutes = Math.floor( (total/60) % 60 );
    const hours = Math.floor( (total/(60*60)) % 24 );
    const days = Math.floor( total/(60*60*24) );

    return {
      total,
      days,
      hours,
      minutes,
      seconds
    };
  }
}
