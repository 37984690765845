import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.handleTabs();
  }

  handleTabs() {
    let hash = document.location.hash;
    if (hash) {
      $('a[href="' + hash + '"][data-toggle="tab"]').tab('show');
    };

    $('.nav-tabs a').on('shown.bs.tab', (e) => {
      // instead of window.location.hash = e.target.hash;
      // the following allows me to change the URL without causing a navigation or scroll:
      history.replaceState(null, null, e.target.hash);
    });
  }
}
