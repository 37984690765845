import {default as BaseParticipantItemController} from "./base_participant_item_controller"
import {LocalParticipant, ParticipantEvent, Track, RemoteParticipant} from "livekit-client";
import feather from "feather-icons";

export default class extends BaseParticipantItemController {
  static targets = ['video', 'audio', 'name', 'micStatus']

  connect(){
    super.connect();
    feather.replace();
  }

  attachVideo(){
    const cameraPub = this.participant.getTrack(this.videoTrackType());
    const cameraEnabled = cameraPub && cameraPub.isSubscribed && !cameraPub.isMuted;
    if (cameraEnabled) {
      if(cameraPub.videoTrack) {
        cameraPub.videoTrack.attach(this.videoTarget);
      }
    } else {
      if (cameraPub && cameraPub.videoTrack) {
        cameraPub.videoTrack.detach(this.videoTarget);
      } else {
        this.videoTarget.src = '';
        this.videoTarget.srcObject = null;
      }
    }
  }

  attachAudio(){
    const micPub = this.participant.getTrack(Track.Source.Microphone);
    const micEnabled = micPub && micPub.isSubscribed && !micPub.isMuted;
    if (micEnabled) {
      if (this.participant instanceof RemoteParticipant && micPub.audioTrack) {
        micPub.audioTrack.attach(this.audioTarget);
      }
      this.micStatusTarget.className = 'mic';
      this.micStatusTarget.innerHTML = '<i data-feather="mic"></i>';
    } else {
      this.micStatusTarget.className = 'mic-off';
      this.micStatusTarget.innerHTML = '<i data-feather="mic-off"></i>';
    }
  }

  addListeners(){
    this.participant
      .on(ParticipantEvent.TrackSubscribed, (_, pub) => {
        this.element.livekitParent.log(`subscribed to track ${pub.trackSid}, ${this.participant.identity}`);
        this.refreshMedia();
      })
      .on(ParticipantEvent.LocalTrackPublished, (pub) => {
        this.element.livekitParent.log(`subscribed to local track ${pub.trackSid}, ${this.participant.identity}`);
        this.refreshMedia();
      })
      .on(ParticipantEvent.TrackUnsubscribed, (_, pub) => {
        this.element.livekitParent.log(`unsubscribed from track ${pub.trackSid}`);
        this.refreshMedia();
      })
      .on(ParticipantEvent.LocalTrackUnpublished, (pub) => {
        this.element.livekitParent.log(`unsubscribed from local track ${pub.trackSid}`);
        this.refreshMedia();
      })
      .on(ParticipantEvent.TrackMuted, (pub) => {
        this.element.livekitParent.log(`track was muted ${pub.trackSid} ${this.participant.identity}`);
        this.refreshMedia();
      })
      .on(ParticipantEvent.TrackUnmuted, (pub) => {
        this.element.livekitParent.log(`track was unmuted ${pub.trackSid} ${this.participant.identity}`);
        this.refreshMedia();
      })
      .on(ParticipantEvent.ConnectionQualityChanged, (q) => {
        this.element.livekitParent.log('ConnectionQualityChanged');
        this.element.livekitParent.log(q);
      });
  }

  videoTrackType(){
    return Track.Source.Camera
  }
}
