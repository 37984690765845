import {default as BaseController} from "./base_controller";
import {merge, clone} from "lodash";

export default class extends BaseController {
  static values = merge(clone(super.values), {color: String})

  settings(){
    return {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        show: false,
      },
      series: [
        {
          color: ["#0061f2", "#6900c7", "#f4a100", "#00ac69"],
          name: 'Objednávka',
          type: 'pie',
          radius: ['70%', '90%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: false,
              fontSize: '40',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: this.dataValue
        }
      ]
    }
  }
}
