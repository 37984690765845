import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input']

  connect() {
    this.element.addEventListener('change', this.updateFileName.bind(this))
  }

  updateFileName() {
    const fileName = this.inputTarget.value.split('\\').pop() // get the file name
    const label = this.inputTarget.nextElementSibling // get the label element
    label.innerHTML = fileName // set the label text to the file name
  }
}
