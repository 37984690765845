import {Controller} from "stimulus"
import {LocalParticipant, ParticipantEvent, Track, RemoteParticipant} from "livekit-client";
import feather from "feather-icons";

export default class extends Controller {
  connect(){
    this.participant = this.element.participant;
    this.addListeners();
    this.fillTemplate();
    this.refreshMedia();
  }

  disconnect(){
    this.videoTarget.srcObject = null;
    this.videoTarget.src = '';
    this.audioTarget.srcObject = null;
    this.audioTarget.src = '';
  }

  refreshMedia(){
    this.attachVideo();
    this.attachAudio();
    feather.replace();
  }

  addListeners(){}

  attachVideo(){}
  attachAudio(){}

  fillTemplate() {
    this.nameTarget.innerHTML = `${this.participant.name}${this.participant instanceof LocalParticipant ? ' (you)' : ''}`
  }

  videoTrackType(){}

  togglePin(){
    if (this.element.classList.contains('pinned')) {
      return this.unpin();
    }

    const that = this;

    this.element.parentElement.querySelectorAll('.pinned').forEach(function(element){
      if (element !== that.element) {
        element.classList.remove('pinned');
      }
    })

    const event = new CustomEvent('pinParticipant', {
      detail: {
        participant: this.participant,
        type: this.videoTrackType()
      }
    });
    this.element.parentElement.dispatchEvent(event);
    this.element.classList.add('pinned');
  }

  unpin(){
    this.element.classList.remove('pinned');
    const event = new CustomEvent('unpinParticipant');
    this.element.parentElement.dispatchEvent(event);
  }
}
