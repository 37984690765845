import {Controller} from "stimulus"
import * as echarts from 'echarts';

// Base class for echart stimulues controllers. It contain initialisation of chart and basic target and values
// of stimulus controller
export default class extends Controller {
  static targets = ["chart"];
  static values = {
    data: Array,
    colors: Array
  };

  connect(){
    const chart = echarts.init(this.chartTarget);
    chart.setOption(this.settings());
  }

  // Override this method in children classes with settings of chart
  settings(){}
}
