import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['textarea'];
  static values = {
    skipConnectResize: Boolean
  }

  connect() {
    if (!this.skipConnectResizeValue) {
      console.log("resizing")
      this.textareaTarget.style.height = `${this.textareaTarget.scrollHeight}px`;
    }
  }

  resize() {
    this.textareaTarget.style.height = `${this.textareaTarget.scrollHeight}px`;
  }
}
