import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["output"];

  connect() {
    this.variants = JSON.parse(this.element.getAttribute('data-typing-variants'));
    this.messageIndex = 0;
    this.characterIndex = 0;
    this.isDeleting = false;
    this.typeMessage();
  }

  typeMessage() {
    const currentMessage = this.variants[this.messageIndex];

    this.outputTarget.innerText = currentMessage.slice(0, this.characterIndex);

    if (!this.isDeleting && this.characterIndex === currentMessage.length) {
      setTimeout(() => this.isDeleting = true, 1000);
    } else if (this.isDeleting && this.characterIndex === 0) {
      this.isDeleting = false;
      this.messageIndex = (this.messageIndex + 1) % this.variants.length;
    }

    this.characterIndex += this.isDeleting ? -1 : 1;

    const typingSpeed = this.isDeleting ? this.getRandomTime(20, 80) : this.getRandomTime(20, 180);
    setTimeout(() => this.typeMessage(), typingSpeed);
  }

  getRandomTime(min, max) {
    return Math.random() * (max - min) + min;
  }
}
