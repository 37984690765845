import {default as BaseParticipantItemController} from "./base_participant_item_controller"
import {LocalParticipant, ParticipantEvent, Track, RemoteParticipant} from "livekit-client";
import feather from "feather-icons";

export default class extends BaseParticipantItemController {
  static targets = ['video', 'audio', 'name']

  attachVideo(){
    const screenSharePub = this.participant.getTrack(this.videoTrackType());
    screenSharePub.videoTrack.attach(this.videoTarget);
  }

  attachAudio(){
    if (this.participant instanceof RemoteParticipant) {
      const screenSharePub = this.participant.getTrack(Track.Source.ScreenShareAudio);
      if(screenSharePub && screenSharePub.audioTrack) {
        screenSharePub.audioTrack.attach(this.audioTarget);
      }
    }
  }

  addListeners(){
    this.participant
      .on(ParticipantEvent.TrackUnsubscribed, (_, pub) => {
        if(pub.source === this.videoTrackType()) {
          this.element.livekitParent.log(`unsubscribed from screen share track ${pub.trackSid}`);
          this.element.remove();
        }
      })
      .on(ParticipantEvent.LocalTrackUnpublished, (pub) => {
        if(pub.source === this.videoTrackType()) {
          this.element.livekitParent.log(`unsubscribed from screen share local track ${pub.trackSid}`);
          this.element.remove();
        }
      })
  }

  videoTrackType(){
    return Track.Source.ScreenShare
  }
}
