import { Controller } from "stimulus"
import moment from "moment"
import "daterangepicker"

export default class extends Controller {
  connect() {
    this.initializeDatePicker(this.element)

    $(document).on('cocoon:after-insert', (e, added_html) => {
      this.initializeDatePicker(added_html);
    });

    $('#modal-window').on('shown.bs.modal', (e) => {
      this.initializeDatePicker(e.relatedTarget);
    })
  }

  initializeDatePicker(parent_element) {
    $('input[data-date-picker-target="date"]', parent_element).daterangepicker({
      singleDatePicker: true,
      autoUpdateInput: false,
      locale: {
        format: "DD/MM/YYYY",
        applyLabel: "OK",
        cancelLabel: "Zrušit",
        fromLabel: "Od",
        toLabel: "Do",
        customRangeLabel: "Vlastní",
        weekLabel: "W",
        daysOfWeek: [
          "Ne",
          "Po",
          "Út",
          "St",
          "Čt",
          "Pá",
          "So"
        ],
        monthNames: [
          "Leden",
          "Únor",
          "Březen",
          "Duben",
          "Květen",
          "Červen",
          "Červenec",
          "Srpen",
          "Září",
          "Říjen",
          "Listopad",
          "Prosinec"
        ],
        firstDay: 1
      }
    });

    $('input[data-date-picker-target="date"]', parent_element).on('apply.daterangepicker', function (ev, picker) {
      $(this).val(picker.startDate.format('DD/MM/YYYY'));
    });
  }
}
