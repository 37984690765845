import { Controller } from "stimulus"
import { LocalParticipant, Track, ParticipantEvent } from "livekit-client";
import feather from "feather-icons";

export default class extends Controller {
  static targets = ['video', 'name', 'signal', 'micStatus']

  // sorted by lowest priority source, currently unused
  // static priority = ['local', 'speaker', 'pin', 'screen_share']

  connect(){
    this.element['bigView'] = this;
    this.videoTarget.addEventListener( "loadedmetadata", this.refreshVideoDimensions.bind(this))
    this.videoTarget.addEventListener( "resize", this.refreshVideoDimensions.bind(this))
  }

  switchVideo(participant, videoSource) {
    let changedParticipant = this.participant !== participant
    if(this.participant === participant && this.videoSource === videoSource) {
      return
    }

    if (this.participant && changedParticipant){
      this._removeListeners();
    }

    this._detachVideoTrack();
    this.participant = participant;
    if (this.participant){
      this.videoSource = videoSource;
      this.track = this.participant.getTrack(videoSource);
      this._attachVideoTrack();
      if(changedParticipant) {
        this._addListeners();
      }

      this.refreshAudioIcon();
      feather.replace();
    } else {
      this.videoSource = null;
      this.track = null;
    }
  }

  _removeListeners(){
    if (this.cachedMediaUpdated === undefined) {
      return
    }

    this.participant
      .off(ParticipantEvent.TrackMuted, this.cachedMediaUpdated)
      .off(ParticipantEvent.TrackUnmuted, this.cachedMediaUpdated)
      .off(ParticipantEvent.LocalTrackPublished, this.cachedMediaUpdated)
      .off(ParticipantEvent.LocalTrackUnpublished, this.cachedMediaUpdated)
      .off(ParticipantEvent.TrackSubscribed, this.cachedMediaUpdated)
      .off(ParticipantEvent.TrackUnsubscribed, this.cachedMediaUpdated)
  }

  _addListeners(){
    // Bind creating a new function, so if we try to remove listener with .off(ParticipantEvent.TrackMuted, this._mediaUpdated.bind(this)),
    // this._mediaUpdated.bind(this) call create new function and ignore previous one, so we need to save it in variable before assigning to listener
    // to keep its reference for future removing
    this.cachedMediaUpdated = this._mediaUpdated.bind(this)
    this.participant
      .on(ParticipantEvent.TrackMuted, this.cachedMediaUpdated)
      .on(ParticipantEvent.TrackUnmuted, this.cachedMediaUpdated)
      .on(ParticipantEvent.LocalTrackPublished, this.cachedMediaUpdated)
      .on(ParticipantEvent.LocalTrackUnpublished, this.cachedMediaUpdated)
      .on(ParticipantEvent.TrackSubscribed, this.cachedMediaUpdated)
      .on(ParticipantEvent.TrackUnsubscribed, this.cachedMediaUpdated)
  }

  _mediaUpdated(pub){
    if (pub.source === this.videoSource) {
      if (pub.isMuted) {
        this._detachVideoTrack();
      } else {
        this.track = this.participant.getTrack(this.videoSource);
        this._attachVideoTrack();
      }
    }

    // When user cancel screenshare, this.participant is sometimes null because it is cleared in livekit_controller
    if (this.participant) {
      this.refreshAudioIcon();
      feather.replace();
    }
  }

  unpin(){
    this._detachVideoTrack();
    this._removeListeners();
    this.videoSource = null;
    this.participant = null;
    this.track = null;
  }

  _attachVideoTrack(){
    if (this.track && this.track.isSubscribed && !this.track.isMuted) {
      if (this.videoSource === Track.Source.Camera && this.participant instanceof LocalParticipant) {
        this.videoTarget.style.transform = 'scale(-1, 1)'; // flip local camera
      } else {
        this.videoTarget.style.transform = '';
      }
      if (this.track.videoTrack) {
        this.track.videoTrack.attach(this.videoTarget);
      }
    } 
  }

  _detachVideoTrack(){
    this.videoTarget.classList.remove('wide');

    if (this.track && this.track.videoTrack) {
      this.track.videoTrack.detach(this.videoTarget);
    } else {
      this.videoTarget.src = '';
      this.videoTarget.srcObject = null;
    }
  }

  refreshAudioIcon(){
    const micPub = this.participant.getTrack(Track.Source.Microphone);
    const micEnabled = micPub && micPub.isSubscribed && !micPub.isMuted;
    if (micEnabled) {
      this.micStatusTarget.className = 'mic';
      this.micStatusTarget.innerHTML = '<i data-feather="mic"></i>';
    } else {
      this.micStatusTarget.className = 'mic-off';
      this.micStatusTarget.innerHTML = '<i data-feather="mic-off"></i>';
    }
    this.nameTarget.innerHTML = `${this.participant.name}${this.participant instanceof LocalParticipant ? ' (you)' : ''}`
  }

  refreshVideoDimensions(){
    if (!this.videoTarget.videoHeight || !this.videoTarget.videoWidth) {
      return
    }

    // Dont use cameraPub.dimensions.height as it reports wrong dimensions (often switched width and height values if video source is mobile phone)
    if (this.videoTarget.videoHeight > this.videoTarget.videoWidth || this.element['livekitParent'].activatedScreenShare) {
      this.videoTarget.classList.remove('wide');
    } else {
      this.videoTarget.classList.add('wide');
    }
  }

  resetCurrent(){
    this._detachVideoTrack();
    if (this.participant) {
      this.unpin()
    }
    this.nameTarget.innerHTML = '';
    this.micStatusTarget.className = '';
    this.micStatusTarget.innerHTML = '';
  }
}
