import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.wrapEeInText();
  }

  wrapEeInText() {
    let originalText = this.element.textContent || this.element.innerText;
    let newText = originalText.replace(/(ee)/g, '<span class="text-edueeno">$1</span>');
    this.element.innerHTML = newText;
  }
}
